










































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import TemplateComponent from '@/components/TemplateComponent.vue'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: { TemplateComponent },
})
export default class AccountingRuleList extends Vue {
  appendSitePrefix = appendSitePrefix

  isDeleting = false
  selected = []

  headers = []
  accountingRules = []
  search = ''
  disabledCount = 0

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  created() {
    this.$axios.get('/v3/economy/sales-accounting-rules/list/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.accountingRules = response.data.data.accountingRules
      this.accountingRules.map((item) => {
        if (item.fallbackType !== null) this.disabledCount += 1
      })
    })
  }

  selectAllToggle(props) {
    if (this.selected.length !== this.accountingRules.length - this.disabledCount) {
      this.selected = []
      props.items.forEach((item) => {
        if (item.fallbackType === null) {
          this.selected.push(item)
        }
      })
    } else this.selected = []
  }

  deleteAccountingRule(item) {
    if (confirm(this.$t('c:common:Are you sure you want to delete this item(s)?').toString())) {
      this.isDeleting = true

      let selectedRows
      if (item === null) {
        selectedRows = this.selected
      } else {
        selectedRows = [item]
      }

      const deleteData = {
        selectedRows: selectedRows,
      }
      this.$axios
        .delete('/v3/economy/sales-accounting-rules/list/delete', { data: deleteData })
        .then((response) => {
          for (let i = 0; i < selectedRows.length; i++) {
            const index = this.accountingRules.indexOf(selectedRows[i])
            this.accountingRules.splice(index, 1)
          }
          vxm.alert.success({
            content: this.$t('c:common:Successfully deleted') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    }
  }
}
